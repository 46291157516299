import React from 'react'
import img5 from '../../assets/img/pm-1.svg'
import img1 from '../../assets/img/new-form-icon-1.svg'
import img2 from '../../assets/img/new-form-icon-2.svg'
import img3 from '../../assets/img/new-form-icon-3.svg'
import img4 from '../../assets/img/new-form-icon-4.svg'
import indiaFlag from '../../assets/img/bd.png'
import { FaLocationArrow, FaMailBulk, FaPhone } from 'react-icons/fa'


const ContactUs = () => {
    
    return (
        <div className='contact'>
            <div className="container">
                <div className='section-width'>
                    <h2 className='contact-title'>We’d Love To Hear From You</h2>
                    <p className='contact-subTitle'>Get Custom Solutions, Recommendations, Resumes, or, Estimates.
                        Confidentiality & Same Day Response Guaranteed!</p>
                </div>
                <div className='contact-mail'>
                    <div className='location-left-site'>
                        <h4>Our Locations</h4>
                        <div>
                            <div className='india-location'>

                                <img style={{ borderRadius: "100%", width: "40px" }} src={indiaFlag} alt="" />

                                <h4>Bangladesh</h4>
                            </div>
                            <div className='address-contact'>
                                <div>
                                    <strong>Mymenshing</strong>
                                    <p style={{ marginBottom: "10px", marginTop: "10px" }}> <FaLocationArrow /> 34/ga Shankipara Health Officer's Lane, Mymensingh 2200</p>
                                    <p style={{}}><FaMailBulk /> emransubcse@gmail.com</p>
                                    <p className='contact-number'><FaPhone /> 01303194454</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='contact-right-site'>
                        <form action="">
                            <div>
                                <input className='input-text' type="text" placeholder='Name' />
                            </div>
                            <div>
                                <input className='input-text' type="text" placeholder='Work Email' />
                            </div>
                            <div>
                                <input className='input-text' type="text" placeholder='Mobile Number' />
                            </div>
                            <div>
                                <input className='input-text' type="text" placeholder='Company' />
                            </div>
                            <div>
                                <textarea className='input-text' name="" id="" placeholder='Your Message'></textarea>
                            </div>
                            <div className='contact-btn'>
                                <button>Send Now</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs