import React from 'react'
import { FaFacebookSquare, FaInstagramSquare, FaTwitter, FaYoutube, FaWhatsapp, FaLinkedin } from "react-icons/fa";

const Footer = () => {
    return (
        <div className='footer-div'>
            <footer>
                <div class="row-footer primary">
                    <div class="column-footer about">

                        <h3>Web Arts Fact</h3>

                        <p>
                            Empowering your digital journey. Expertise in web, graphics, and marketing. Elevate your online presence with WebArtsFactory.
                        </p>

                        <div class="social">
                            <a href="https://www.facebook.com/webartsfactory" target='_blank'>
                                <FaFacebookSquare />
                            </a>
                            <a href="https://www.linkedin.com/company/webartsfactory" target='_blank'>
                                <FaLinkedin />
                            </a>
                            <a href="https://api.whatsapp.com/message/CGNWRKOFCR5WO1?autoload=1&app_absent=0" target='_blank'>
                                <FaWhatsapp />
                            </a>
                        </div>
                    </div>

                    <div class="column-footer links">
                        <h3>Our Address</h3>

                        <p>34/ga Shankipara Health Officer's Lane, Mymensingh 2200</p>

                        {/* <p style={{marginTop: '10px'}}>21B Petticoat Square
                            London
                            E1 7EF, United Kingdom</p> */}

                    </div>


                    <div class="column-footer links">
                        <h3>Links</h3>
                        <ul>
                            <li>
                                <a href="#faq">F.A.Q</a>
                            </li>
                            <li>
                                <a href="#cookies-policy">Cookies Policy</a>
                            </li>
                            <li>
                                <a href="#terms-of-services">Terms Of Service</a>
                            </li>
                            <li>
                                <a href="#support">Support</a>
                            </li>
                        </ul>
                    </div>

                    <div class="column-footer subscribe links">
                        <h3>Newsletter</h3>
                        <div>
                            <input type="email" placeholder="Your email id here" />
                            <button className='button-footer'>Subscribe</button>
                        </div>

                    </div>

                </div>
                <p style={{ textAlign: "center", marginBottom: "30px" }}>Copyright &copy; 2023 WebArtsFactory</p>
                {/* <div class="row-div copyright">
                    
                </div> */}
            </footer>
        </div>
    )
}

export default Footer